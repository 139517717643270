<template>
    <BT-List
        :canDelete="false"
        dense
        hideFooter
        hideTableHeader
        isSimple
        :navigation="navigation"
        :onPullSuccessAsync="pullMappings"
        :params="cParams">
        <template slot-scope="{ item }">
            <v-list-item-content>
                <v-list-item-subtitle>
                    <BT-Entity
                        v-if="externalProperty != null"
                        navigation="products"
                        :itemValue="item.data.itemID"
                        itemText="productName" />
                    <span v-else>{{ item.data.externalProperty }}</span>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle>
                    <v-text-field
                        hide-details
                        v-model="item.data.externalValue"
                        :disabled="!isEditing || !$canEdit(navigation)"
                        label="value"
                        :loading="item.isLoading"
                        @change="updateTracking(item)" />
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </BT-List>
</template>

<script>
export default {
    name: 'BT-Mappings',
    components: {
        ////BTList: () => import('~components/BT-List.vue')
    },
    data: function() {
        return {
            itemList: null,     
            //isLoaded: false,       
        }
    },
    props: {
        navigation: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        itemType: {
            type: String,
            default: 'Product'
        },
        externalParty: {
            type: String,
            default: 'xero'
        },
        externalProperty: {
            type: String,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        externalPropertyOptions: {
            type: Array,
            default: null
        },
        externalPropertyOptionSet: {
            type: String,
            default: 'xero'
        }
    },
    computed: {
        cPropertyOptions() {
            if (this.externalPropertyOptions != null && this.externalPropertyOptions.length > 0) {
                return this.externalPropertyOptions;
            }
            else if (this.externalPropertyOptionSet != null) {
                if (this.externalPropertyOptionSet == 'xero') {
                    return ['Description', 'TaxType', 'ItemCode', 'AccountCode', 'TrackingCategoryNameOne', 'TrackingCategoryOptionOne', 'TrackingCategoryNameTwo', 'TrackingCategoryOptionTwo'];
                }
            }
            return null;   
        },
        cParams() {
            if (this.externalProperty != null) {
                return { query: `ItemType=${this.itemType} ANDALSO ExternalPartyID=${this.externalParty} ANDALSO ExternalProperty=${this.externalProperty}` };
            }
            else {
                return { query: `ItemType=${this.itemType} ANDALSO ExternalPartyID=${this.externalParty} ANDALSO ItemID=${this.itemID}` };
            }
        }
    },
    methods: {
        cFilter(list) {
            var rList = [];
            var self = this;

            // if (this.isLoaded) {
            //     return this.itemList;
            // }

            // this.isLoaded = true;

            if (this.cPropertyOptions == null) {
                rList = list.map(x => { return { isChanged: false, isLoading: false, original: JSON.stringify(x), data: self.copyDeep(x) }})
            }
            else {
                this.cPropertyOptions.forEach(prop => {
                    var eItem = list.find(x => x.externalProperty == prop);
                    if (eItem == null) {
                        eItem = {
                            id: null,
                            rowVersion: null,
                            externalPartyID: this.externalParty,
                            externalProperty: prop,
                            externalValue: '',
                            itemType: this.itemType,
                            itemID: this.itemID,
                        };
                    }

                    rList.push({
                        data: this.copyDeep(eItem),
                        original: JSON.stringify(eItem),
                        isLoading: false,
                        isChanged: false
                    });
                });
            }

            this.itemList = rList;

            return rList;
        },
        async pullMappings(mappings) {

            if (this.externalProperty != null) {
                var products = await this.$BlitzIt.store.getAll('products');

                return products.map(x => {
                    var eMap = mappings.find(m => m.itemID == x.id);

                    return {
                        id: eMap != null ? eMap.id : null,
                        rowVersion: eMap != null ? eMap.rowVersion : null,
                        externalPartyID: eMap != null ? eMap.externalPartyID : this.externalParty,
                        externalProperty: eMap != null ? eMap.externalProperty : this.externalProperty,
                        externalValue: eMap != null ? eMap.externalValue : null,
                        itemType: eMap != null ? eMap.itemType : this.itemType,
                        itemID: eMap != null ? eMap.itemID : x.id
                    }
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) 
                    }
                });
            }
            else if (this.itemID != null) {
                return this.cPropertyOptions.map(prop => {
                    var eMap = mappings.find(x => x.externalProperty == prop);

                    return {
                        id: eMap != null ? eMap.id : null,
                        rowVersion: eMap != null ? eMap.rowVersion : null,
                        externalPartyID: eMap != null ? eMap.externalPartyID : this.externalParty,
                        externalProperty: eMap != null ? eMap.externalProperty : prop,
                        externalValue: eMap != null ? eMap.externalValue : null,
                        itemType: eMap != null ? eMap.itemType : this.itemType,
                        itemID: eMap != null ? eMap.itemID : this.itemID
                    }
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) 
                    }
                });
            }

            return [];
        },
        updateTracking(item) {
            //item.isChanged = item.original != JSON.stringify(item.data);
            if (item.original != JSON.stringify(item.data)) {
                //save
                this.saveMapping(item);
            }
        },
        saveMapping(mapping) {
            mapping.isLoading = true;
            
            if (mapping.data.externalValue == null || mapping.data.externalValue.length == 0) {
                this.$BlitzIt.api.delete(this.navigation, mapping.data)
                    .then(() => {
                        mapping.data.rowVersion = null;
                        mapping.data.id = null;
                        mapping.original = JSON.stringify(mapping.data);
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; });
            }
            else if (mapping.data.id == null) {
                this.$BlitzIt.api.post(this.navigation, mapping.data)
                    .then(res => {
                        mapping.original = JSON.stringify(res.data.data);
                        mapping.data = res.data.data;
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; })
            }
            else {
                this.$BlitzIt.api.patch(this.navigation, mapping.data)
                    .then(res => {
                        mapping.original = JSON.stringify(res.data.data);
                        mapping.data = res.data.data;
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; })
            }
        }
    }
}
</script>